class AuthMethod {
    public sortOrder: number = 0;
    public id: string | null = null;
    public path: string | null = null;
}

class NullAuthMethod extends AuthMethod {
    constructor() {
        super();
    }
}

enum AuthState {
    Idle = 0,
    PathInProgress = 1,
    PathPartialComplete = 2,
    PathComplete = 3,
    PathCompletePendingFederation = 4,
    PathCancelled = 7,
    PathFailed = 9,
    LogoutCompleted = 10,
    LogoutFailed = 19,
    Restart = 20
}

class MethodsResponse {
    public authState: AuthState = AuthState.Idle;
    public challengeMethods: AuthMethod[] | null = null;
    public launchExternal: boolean = false;
    public launchUri: string | null = null;
}

class AuthAttribute {
    public name: string | null = null;
    public value: string | null = null;
    public sortOrder: number = 0;
    public type: string = "text";
    public validationError: string | null = null;
    public persisted: boolean = false;
    public hasFocus: boolean = false; // added on frontend
}

class AuthRequest {

    static AttributeAuthChallenge: string = "attr-fido2-auth-challenge";
    static AttributeAuthResponse: string = "attr-fido2-auth-response";
    static AttributeRegistrationChallenge: string = "attr-fido2-registration-challenge";
    static AttributeRegistrationResponse: string = "attr-fido2-registration-response";
    static AttributeBankIdChallengeQr: string = "attr-bankid-challenge-qr";
    static AttributeBankIdPnr: string = "attr-bankid-pnr";
    static AttributeOtpEnrollmentQrCode: string = "attr-otp-enrollment-qrcode";
    static AttributePwdUsername: string = "attr-pwd-username";
    static AttributeFido2Username: string = "attr-fido2-username";
    static AttributeCaptchaImage: string = "attr-captcha-image";
    static AttributeChallengeMessage: string = "attr-challenge-message";
    static AttributeReCaptchaSiteKey: string = "attr-recaptcha-site-key";
    static AttributeReCaptchaAction: string = "attr-recaptcha-action";
    static AttributeReCaptchaScriptUrl: string = "attr-recaptcha-script-url";
    static AttributeReCaptchaResponse: string = "attr-recaptcha-response";

    public cancel: boolean = false;
    public selectedMethodAndPath: string | null = null;
    public challengeAttributes: AuthAttribute[] | null = null;
    public startupParams: string | null = null;
}

enum UserMessageType {
    Information = 1,
    Warning = 2,
    Error = 3,
    Success = 4,
}

class UserMessage {
    public message: string | null = null;
    public messageType: UserMessageType = UserMessageType.Information;
    public detailedMessage: string | null = null;
    public incidentId: string | null = null;
}

class AuthResponse extends AuthRequest {
    public userLocale: string = "en";
    public message: UserMessage | null = null;
    public authState: AuthState = AuthState.Idle;
    public pollServer: boolean = false;
    public launchExternal: boolean = false;
    public launchUri: string | null = null;
}

class LogoutResponse {
    public authState: AuthState = AuthState.Idle;
}

class User {
    userId: string | null = null;
    username: string | null = null;
    displayName: string | null = null;
    emailAddress: string | null = null;
    mobilePhone: string | null = null;
    authenticatedWith: string[] = [];
}

class App {
    id: string | null = null;
    name: string | null = null;
    description: string | null = null;
}


class LinkSet {
    public Header: Link;
    public Links : Link[] | null;
  
    constructor(header: Link, links: Link[] | null) {
      this.Header = header;
      this.Links = links;
    }
  }
  
  class Link {
    public Uri:string;
    public Text: string;
    public ImageSrc: string | null;
  
    constructor(uri: string, text : string, imageSrc: string | null)
    {
      this.Uri = uri;
      this.Text = text;
      this.ImageSrc = imageSrc;
    }
  }

export { AuthMethod, NullAuthMethod, AuthState, AuthAttribute, UserMessage, UserMessageType, AuthRequest, AuthResponse };
export { MethodsResponse };
export { LogoutResponse, User, App }
export { LinkSet, Link }